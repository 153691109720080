import { secureTranslation } from '@/i18n'
import { ref } from 'vue'
import store from '@/store'
import utils from '@/slango-multiverse/helpers/utils'
import customProtocolCheck from 'custom-protocol-check'
import { install } from '@/helpers/APIconnection'
import { PublicClientApplication } from '@azure/msal-browser'

export function InitGlobalMethods(properties) {

    const AttemptToOpenRobotekaFailed = () => {

        const cancelButton = { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined }
        const downloadButton = { variant: 'primary', text: secureTranslation('home.download-installer'), click: async () => {

            const res = await install.slango()
            
            if ( res?.status === 200 ) { properties.$download({ blob: res.data, filename: 'Slango Setup', extension: '.exe' }) }
            store.state.ui.promptModal = undefined
        }}

        store.state.ui.promptModal = {
    
            title: secureTranslation('app.roboteka.installation.title'),
            text: secureTranslation('app.roboteka.installation.text'),
            buttons: [ cancelButton, downloadButton ]
        }
    }
    
    const AttemptToOpenRecorderFailed = () => {
    
        store.state.ui.promptModal = {
    
            title: secureTranslation('app.recorder.installation.title'),
            text: secureTranslation('app.recorder.installation.text'),
    
            buttons: [
    
                { variant: 'outlined', text: secureTranslation('general.cancel'), click: () => store.state.ui.promptModal = undefined },
                { variant: 'primary', text: secureTranslation('home.download-installer'), click: async () => {
                    
                    const res = await install.recorder()

                    if ( res?.status === 200 ) { properties.$download({ blob: res.data, filename: 'Slango Task Recorder Installer', extension: '.zip' }) }
                    store.state.ui.promptModal = undefined
                }}
            ]
        }
    }

    properties.$st = secureTranslation
    properties.$electron = process.env.IS_ELECTRON

    properties.$openExternal = (url, sameTab) => { if ( !url ) return; process.env.IS_ELECTRON ? require('electron').shell.openExternal(url) : window.open(url, sameTab ? '_self' : '') }
    properties.$openDesktopApp = (url, failCallback, successCallback, timeout = 2000) => customProtocolCheck(url, failCallback, successCallback, timeout)
    properties.$openSlangoDesktop = () => properties.$openDesktopApp('slango://', AttemptToOpenRobotekaFailed, undefined, 1000)
    properties.$openTaskRecorder = () => properties.$openDesktopApp('SlangoTaskRecorder://', AttemptToOpenRecorderFailed, undefined, 1000)

    // >> Inputs validation

    properties.$registeredInputs = { all: {} }

    properties.$validate = (group = 'all') => {

        if ( !properties.$registeredInputs ) return true

        const inputs = Object.values(properties.$registeredInputs[group] ?? {})

        return inputs.map(i => {
            
            if ( i.validate ) return i.validate()
            return true
        
        }).every(i => i)
    }

    Object.entries(utils ?? {}).forEach(method => properties['$' + method[0]] = method[1])

    // =============== Toast messages ===============

    const toastMessages = ref({})
    const timeouts = {}

    properties.$toasts = toastMessages.value

    properties.$createToastMessage = (options) => {

        if ( options.key !== undefined ) { options.key = (options.title ?? '' + options.text ?? '') }
        
        clearTimeout(timeouts[options.key])
        properties.$toasts[options.key] = options

        if ( options.alive ) { timeouts[options.key] = setTimeout(() => delete properties.$toasts[options.key], options.alive) }
    }

    properties.$clipboard = (text) => {
        
        if (text) {
            
            navigator.clipboard.writeText(text)
            properties.$createToastMessage({ key: 'clipboard', text: 'Copiado!', alive: 1500, fixed: true, close: false })
        }
    }

    properties.$microsoftLogin = async () => {

        const msalConfig = {

            auth: {
                clientId: '3e77936b-5376-49f8-9f1f-ec34a663a7dd',
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: '/', // La URL a la que se redirigirá después de iniciar sesión
            },
            cache: {
                cacheLocation: "localStorage", // Especifica la ubicación del almacenamiento en caché
                storeAuthStateInCookie: false, // Establece si debe almacenar el estado de autenticación en una cookie
            }
        }

        const msalInstance = new PublicClientApplication(msalConfig)

        await msalInstance.initialize()

        try {

            const loginResponse = await msalInstance.loginPopup({ prompt: 'select_account' })
            console.log('Usuario autenticado:', loginResponse)

        } catch (error) {

            console.error('Error al iniciar sesión:', error)
        }
    }

    properties.$navigationMenu = (container, current) => {

        if ( !container || !(container instanceof HTMLElement) ) { return }
    
        let underline = container.querySelector('#active-element')

        const children = Array.from(container.children)
    
        if ( !underline ) {
    
            container.classList.add('relative')
            underline = document.createElement('div')
            underline.id = 'active-element'
            underline.classList.add('absolute', 'bottom-0', 'h-0.5', 'bg-current')
            container.appendChild(underline)
        }

        underline.style.transition = 'width 200ms, left 200ms'
    
        current = typeof current === 'string' ? container.querySelector('.' + current) : children[current]

        if ( !current ) return

        children.forEach(c => c.classList.remove('active-element'))
        current.classList.add('active-element')
    
        underline.style.width = current.clientWidth + 'px'
        underline.style.left = current.offsetLeft + 'px'
    }

    // >> Timers

    const timers = {}

    properties.$newTimer = ({ key, callback = () => {}, duration = 30 }) => {

        const timer = timers[key] = ref({ callback })

        timer.value.clear = () => {
            
            clearInterval(timer.value.interval)
        }

        clearInterval(timer.value.interval)

        timer.value.remaining = duration

        timer.value.interval = setInterval(() => {

            timer.value.remaining --

            if ( timer.value.remaining <= 0 ) {

                timer.value.callback()
                timer.value.clear()
                delete timers[key]
            }

        }, 1000)

        return timer
    }
}