<template>

	<div class="relative h-12 w-full flex items-center justify-between bg-main-100 shrink-0 drag select-none">

		<div class="absolute left-0 flex items-center gap-4 overflow-hidden px-3"> <!-- Container -->

			<HamburgerButton
				class="drag-none"
				@click="this.store.state.navigationMenuOpen = !this.store.state.navigationMenuOpen"
				:open="this.store.state.navigationMenuOpen"
				@focus="this.utils.handleFocusGroup(() => this.store.state.navigationMenuOpen = false)"
				focus-group="navigation-menu-controller"/>

			<div
				v-if="store.state.currentUser?.userData"
				class="w-fit hidden md:block text-white truncate overflow-hidden">
				
				{{ $st('app.hello') }} <span class="font-bold"> {{ ' ' + store.state.currentUser?.userData.name }} </span> 
			</div>

		</div>

		<div class="absolute left-1/2 -translate-x-1/2 flex items-center gap-4">

			<p v-if="this.userSubscription.active && !this.userSubscription.trialing" class="max-md:hidden text-white font-medium"> Slango Plan {{ this.userSubscription.name }} </p>
			
			<div
				v-if="this.userSubscription.balance !== undefined"
				class="h-7 flex items-center gap-2 bg-pro_dark text-white text-xs px-3 rounded-2xl shadow-weak_material"
				@click="this.$router.push({ name: 'Payments' })">
				
				<i class="icon-diamond text-[20px]"/>
				{{ this.$st('payments.current-slans', { value: this.userSubscription.balance }) }}

			</div>
		
		</div>

	</div>

</template>

<script>
import store from '@/store'
import HamburgerButton from '@/slango-multiverse/components/HamburgerButton'
import utils from '@/slango-multiverse/helpers/utils'

export default {
  
	data: function() {

		return {

			store,
			utils
		}
	},

	components: { HamburgerButton },

	computed: {

		getAppVersion() { return store.state.appVersion },

		CurrentRouteName() { return this.$route.name },

		userSubscription() { return store.getters.currentUserSubscription }
	},

	methods: {

		RemainingSubscriptionTime: function(date) {

			date = new Date(date) - new Date()

			const seconds = Math.floor(date / 1000)

			let interval = seconds / 31536000;

			const years = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( years + ' ' + this.$st('utils.year', years) ) + '</span>'}, years) }
			interval = seconds / 2592000

			const months = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( months + ' ' + this.$st('utils.month', months) ) + '</span>'}, months) }
			interval = seconds / 86400

			const days = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( days + ' ' + this.$st('utils.day', days) ) + '</span>'}, days) }
			interval = seconds / 3600

			const hours = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( hours + ' ' + this.$st('utils.hour', hours) ) + '</span>'}, hours) }
			interval = seconds / 60

			const minutes = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( minutes + ' ' + this.$st('utils.minute', minutes) ) + '</span>'}, minutes) }

			return 'now';

		},

	}
}
</script>

<style>

.ham-button:active {
  transform: translateY(2px);
}

.app-control-buttons-container {
  height: 100%;
  width: 180px;
  flex-shrink: 0;
}
</style>