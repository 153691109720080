<template>

	<div class="h-full lg:w-[90px] shrink-0" focus-group="navigation-menu-controller"></div>

	<div
		class="absolute h-full max-w-full top-0 left-0 bg-main-100 text-main-5 transition-[width,left] duration-300 z-50"
		:class="{'max-lg:left-[-90px]': !this.store.state.navigationMenuOpen}"
		focus-group="navigation-menu-controller">

		<div class="h-full flex overflow-y-scroll scroll-hidden">

			<!----- Submenu ----->
			<div
				class="flex flex-col transition-[width,opacity] duration-300 text-sm"
				:class="[this.store.state.navigationMenuOpen
					? 'w-[360px]'
					: 'w-0 opacity-0 pointer-events-none']">

				<div class="h-full flex flex-col gap-6 p-4">

					<div class="flex flex-col gap-5">

						<p class="truncate px-2 font-semibold border-b border-main-50 pb-1" v-if="this.currentUser?.userData">{{ this.currentUser.userData.email }}</p>

						<p class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]" @click="this.$router.push({ name: 'UserProfile' }); this.store.state.navigationMenuOpen = false">{{ $st('app.account.name') }}</p>

						<p class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]" @click="this.$router.push({ name: 'Payments' }); this.store.state.navigationMenuOpen = false">{{ $st('app.payments.name') }}</p>

						<div class="border-t border-dotted border-main-10"/>

						<p class="truncate px-2 py-1 hover:bg-main-5/20 rounded cursor-pointer transition-[background]" @click="this.user.logout(); this.store.state.navigationMenuOpen = false">{{ $st('app.logout') }}</p>

					</div>

					<SubscriptionCard class="mt-auto self-center"/>

					<p class="text-main-50 text-xs">{{ this.store.getters.appVersion }}</p>

				</div>

			</div>

			<div class="h-full w-[90px] flex flex-col items-center gap-3 shrink-0 py-4" ref="sidebar-menu-container">

				<div
					v-for="item in (this.CustomSidebarArguments ? this.CustomSidebarArguments.items : this.appSidebarArguments.items)?.filter(r => !r.hidden?.())" :key="item"
					class="w-full flex flex-col items-center">

					<div
						v-if="item.type === 'button'"
						class="relative group w-[60px] aspect-square flex flex-col items-center justify-center gap-1 rounded-md bg-main-5 shadow-weak_material cursor-pointer text-main-100 select-none"
						:class="{'selected-navigation-item': item.isActive?.()}"
						@click="item.onclick?.(); this.store.state.navigationMenuOpen = false">

						<i v-if="item.icon" class="text-3xl" :class="item.icon"/>
						<img v-if="item.image" :src="item.image" class="h-7" alt="">

						<p class="text-[9px] font-bold">{{ this.$st(item.label) }}</p>

						<div
							class="absolute right-[calc(100%+6px)] h-3 w-1 scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 bg-main-5 shrink-0 rounded-xl transition-[height,transform,opacity] duration-300 animate-fade"
							:class="{'!h-8 !scale-100 !opacity-100': item.isActive?.()}"/>

					</div>

					<div
						v-else-if="item.type === 'separator'"
						class="h-px w-2/3 bg-main-5"></div>

				</div>

				<img src="@/assets/icons/slango/slango_logotype_white_vertical.svg" class="h-12 w-12 mt-auto !animate-none !opacity-100">

			</div>

		</div>

	</div>

</template>

<script>
import store from '@/store'
import { user } from '@/helpers/APIconnection'
import SubscriptionCard from '@/components/SubscriptionCard'

export default {

	components: { SubscriptionCard },
  
  computed: {

    currentUser() { return store.getters.currentUser },

    CustomSidebarArguments() {

		if (store.state.customSidebarArguments?.animation === 'cascade') {

			this.$nextTick(() => {

				const items = this.$refs['sidebar-menu-container']?.children

				if ( !items ) { return }

				items.forEach((item, index) => { item.classList.add('menu-item-animation', 'opacity-0'); item.style['animation-delay'] = (50 * index) + 'ms' })
			})
		}

		return store.state.customSidebarArguments
    },
  },

	data: function() {

		return {

			user,
			store,
			appSidebarArguments: {

				drawer: true,
				items: [

					{
						type: 'button',
						label: 'app.home.name',
						icon: 'icon-home',
						onclick: () => { this.$router.push({ name: 'Home' }); this.$cgtm({ action: 'click-menu-vertical', label: 'home' }) },
						isActive: () => { return this.$route.name === 'Home' }
					},
					{ type: 'separator' },
					{
						type: 'button',
						label: 'app.workspaces.name',
						icon: 'icon-workspace',
						onclick: () => { this.$router.push({ name: 'Workspace-Assessor' }); this.$cgtm({ action: 'click-menu-vertical', label: 'workspace' }) },
						isActive: () => { return this.$route.name === 'Workspace-Assessor' }
					},
					{
						type: 'button',
						label: 'app.consultant.name',
						icon: 'icon-flow',
						onclick: () => { this.$router.push({ name: 'FlowsPanel' }) },
						isActive: () => { return this.$route.name === 'FlowsPanel' },
						hidden: () => !this.$router.resolve({ name: 'AltasBajasDashboard' }).meta.allowed()
					},
					{
						type: 'button',
						label: 'app.altasbajas.name',
						icon: 'icon-',
						onclick: () => { this.$router.push({ name: 'AltasBajasDashboard' }) },
						isActive: () => { return this.$route.name === 'AltasBajasDashboard' },
						hidden: () => !this.$router.resolve({ name: 'AltasBajasDashboard' }).meta.allowed()
					},
					{
						type: 'button',
						label: 'app.notifications.name',
						icon: 'icon-',
						onclick: () => { this.$router.push({ name: 'NotificationsDashboard' }) },
						isActive: () => { return this.$route.name === 'NotificationsDashboard' },
						hidden: () => !this.$router.resolve({ name: 'NotificationsDashboard' }).meta.allowed()
					},
					{
						type: 'button',
						label: 'app.roboteka.name',
						icon: 'icon-roboteka',
						onclick: () => { this.$router.push({ name: 'Roboteka' }), this.$cgtm({ action: 'click-menu-vertical', label: 'roboteka' }) },
						isActive: () => { return this.$route.name === 'Roboteka' }
					},
					{
						type: 'button',
						label: 'app.recorder.name',
						icon: 'icon-recorder',
						onclick: () => { this.$router.push({ name: 'MacroRecorder' }), this.$cgtm({ action: 'click-menu-vertical', label: 'grabador' }) },
						isActive: () => { return this.$route.name === 'MacroRecorder' }
					},
					{
						type: 'button',
						label: 'app.smartbuilder.name',
						icon: 'icon-smart-builder',
						onclick: () => { this.$router.push({ name: 'SmartBuilder' }), this.$cgtm({ action: 'click-menu-vertical', label: 'smart-builder' }) },
						isActive: () => { return this.$route.name === 'SmartBuilder' }
					},
					{ type: 'separator' },
					{
						type: 'button',
						label: 'Frumecar',
						image: require('@/assets/icons/frumecar.svg'),
						onclick: () => { this.$router.push({ name: 'Frumecar' }), this.$cgtm({ action: 'click-menu-vertical', label: 'frumecar' }) },
						isActive: () => { return this.$route.name === 'Frumecar' },
						hidden: () => !this.$router.resolve({ name: 'Frumecar' }).meta.allowed()
					},
					{ type: 'separator', hidden: () => !this.$router.resolve({ name: 'Frumecar' }).meta.allowed() },
					{
						type: 'button',
						label: 'app.help.name',
						icon: 'icon-help',
						onclick: () => { this.$router.push({ name: 'Help' }), this.$cgtm({ action: 'click-menu-vertical', label: 'help' }) },
						isActive: () => { return this.$route.name === 'Help' }
					},
				]
			}
		}
	},

	methods: {

		IsMenuItemSelected: function(path) {

			this.$route.href === path
		},
	}
}
</script>

<style scoped>
.menu-item-animation {
  animation: menu-item-anim 0.3s ease forwards;
}

@keyframes menu-item-anim {
  from { transform: translateX(-80px) }
  to { transform: translateX(0); opacity: 1; }
}
</style>