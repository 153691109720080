export const commonTechnologies = [
    
    'Discord',
    'Drive',
    'Dropbox',
    'Efficy',
    'Excel',
    'Gmail',
    'Google Calendar',
    'Holded',
    'Hootsuite',
    'Hubspot',
    'Instagram',
    'Linkedin',
    'Mailchimp',
    'Microsoft',
    'Monday',
    'Outlook',
    'PDF',
    'Pipedrive', 
    'PPT',
    'Slack',
    'Suma',
    'Teams',
    'Telegram', 
    'Whatsapp',
    'Word',
    'Zendesk',
    'Zoho',
    'Zoom'
]

export const TIMEOUT = 360000

export const DOCUMENT_BUILDER_REGEXES = Object.freeze({
    // eslint-disable-next-line
    fieldsRegex: /\[\[(?!\[)([^\[\]\s<]| |\[(?!\[)|\](?!\])|<(?!\/p>|br>))+\]\]/g,
    blockConstantRegex: /\[\[((DATE)?TIME|DATE)STAMP\]\]/g,
    blockIfRegex: /\[\[IF\((?<field>.*?)((?<op>(=|&gt;|&lt;))(?<value>.*?))?\?(?<ifTrue>.*?)(:(?<ifFalse>.*?))?\)\]\]/g,
    signatureRegex: /\[\[SIGNATURE\]\]/g,
})

export const STORAGE_KEYS = Object.freeze({
    REMOVE_ON_LOGOUT_REGEX: /^\$logoutRemove:/,
    DOCUMENT_BUILDER: Object.freeze({
        TEMPLATE_DRAFT: '$logoutRemove:DocumentBuilder.templateDraft',
    }),
});

export const CLICKANDCLICK_RUNNING_CHECK = 'tasklist /M "Slango Click And Click.dll"|Findstr Slango'