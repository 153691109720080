import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
    {
        path: '/',
        name: 'Root',
        redirect: '/home'
    },
    {
        path: '/login',
        redirect: '/auth'
    },
    {
        path: '/auth/:mode?',
        name: 'Auth',
        component: () => import ('@/views/Auth/Auth.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import ('@/views/Home.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true },
    },
    {
        path: '/workspace',
        name: 'Workspace',
        component: () => import ('@/views/Workspace.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true },
        children: [
            { path: 'assessor', name: 'Workspace-Assessor', component: () => import ('@/views/Workspaces/Assessor.vue') },
        ]
    },
    {
        path: '/roboteka',
        name: 'Roboteka',
        component: () => import ('@/views/Roboteka.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true }
    },
    {
        path: '/launcher/:id?',
        name: 'ScriptLauncher',
        component: () => import ('@/views/ScriptLauncher.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true }
    },
    {
        path: '/recorder',
        name: 'MacroRecorder',
        component: () => import ('@/views/MacroRecorder.vue'),
        meta: {
            requiresAuth: true,
            topbar: () => !store.state.embedded,
            navigation: () => !store.state.embedded,
        }
    },
    {
        path: '/smartbuilder',
        name: 'SmartBuilder',
        component: () => import ('@/views/SmartBuilder.vue'),
        meta: { requiresAuth: true, requiresPayment: true, topbar: true, navigation: true },
    },
    {
        path: '/notifications',
        name: 'NotificationsDashboard',
        component: () => import ('@/views/NotificationsDashboard.vue'),
        meta: {
            requiresAuth: true,
            topbar: false,
            navigation: false,
            allowed: () => store.state.embedded
        }
    },
    {
        path: '/flows',
        name: 'FlowsPanel',
        component: () => import ('@/views/FlowsPanel.vue'),
        meta: {
            requiresAuth: true,
            topbar: false,
            navigation: false,
            allowed: () => store.state.embedded
        }
    },
    {
        path: '/altasybajas',
        name: 'AltasBajasDashboard',
        component: () => import ('@/views/Asesoria/AltasBajasDashboard.vue'),
        meta: { requiresAuth: true, topbar: false, navigation: false, allowed: () => store.state.embedded }
    },
    {
        path: '/userprofile',
        name: 'UserProfile',
        component: () => import ('@/views/UserProfile.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true }
    },
    {
        path: '/payments',
        name: 'Payments',
        component: () => import ('@/views/Payments.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true }
    },
    {
        path: '/help',
        name: 'Help',
        component: () => import ('@/views/Help.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true }
    },
    {
        path: '/online-form/:id?',
        name: 'OnlineForm',
        component: () => import ('@/views/OnlineForm.vue'),
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import ('@/views/Info/Info.vue')
    },
    {
        path: '/scraper',
        name: 'Scraper',
        component: () => import ('@/views/Scraper.vue'),
        meta: { requiresAuth: true, topbar: true, navigation: true },
    },
    {
        path: '/form/:context/:id?',
        name: 'DynamicForm',
        component: () => import ('@/views/DynamicForm.vue'),
        meta: { requiresAuth: false, topbar: false, navigation: false }
    },
    {
        path: '/frumecar',
        name: 'Frumecar',
        component: () => import ('@/views/Frumecar/index.vue'),
        meta: {
            requiresAuth: true,
            topbar: () => !store.state.embedded,
            navigation: () => !store.state.embedded,
            allowed: () => store.state.currentUser?.userData?.group?.accessToProtectedRoutes?.includes('/frumecar')
        },
    },
    {
        path: '/certificates',
        name: 'Certificates',
        component: () => import ('@/views/CertificatesManager.vue'),
        meta: {
            requiresAuth: true,
            topbar: () => !store.state.embedded,
            navigation: () => !store.state.embedded
        },
    },
    {
        path: '/:catchAll(.*)',
        component: () => import ('@/views/PageNotFound.vue')
    },

    // ========== Develop routes ==========
    {
        path: '/debug-components',
        name: 'DebugComponents',
        component: () => import ('@/views/DebugComponents.vue'),
        meta: { requiresAuth: false, topbar: true, navigation: true, debug: true },
    },
]

const router = createRouter({
    routes,
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory()
})

export default router