import { user } from '@/helpers/APIconnection'
import { InitializeDefaultTranslation, secureTranslation } from '@/i18n'
import store from '@/store'
import router from '@/router'

export async function initApplication() {

    const restoreSession = async (route, next, refreshToken) => {

        store.state.appGlobalLoading.restoreSession = true
    
        const sessionRestored = await user.restoreSession(refreshToken)
    
        if ( !sessionRestored ) {
    
            if ( !process.env.IS_ELECTRON && route ) store.state.nextRoute = route
            next({ name: 'Auth' })
        }
        
        store.state.appGlobalLoading.restoreSession = false
    
        return sessionRestored
    }

    // >>>>>

    store.state.appGlobalLoading = {}

    // ===============| Translations |===============
    store.state.appGlobalLoading.translations = true
    InitializeDefaultTranslation().then(() => store.state.appGlobalLoading.translations = false)
    // ==============================================

    // ===============| Embedded app |===============
    await window.chrome?.webview?.hostObjects.config.GetBaseConfig().then(async r => {

        const config = JSON.parse(r)
        store.state.embedded = !!config

        if ( store.state.embedded ) {

            clearInterval(appStatusInterval)
    
            var appStatusInterval = setInterval(async () => {

                store.state.appStatus = await window.chrome?.webview?.hostObjects.config.AppStatus

            }, 1000)
        }

        if (store.state.embedded && config.RefreshToken) {

            await restoreSession(undefined, router.push, config.RefreshToken?.split(';')[0])
        }

    }).catch()
    // ==============================================

    router.beforeEach( async (to, from, next) => {

        if ( to.meta.debug && process.env.VUE_APP_ENV !== 'dev' ) { next({ path: '/' }); return }
        if ( to.name === 'Auth' ) { store.state.ui = {} }

        store.state.previousRoutePath = from.href // Previous route

        if ( to.meta.requiresAuth && !store.state.currentUser ) { // >> If next route requires auth check authentication status

            const sessionRestored = await restoreSession(to, next)

            if ( !sessionRestored ) return
        }

        if ( to.meta.allowed && !to.meta.allowed() ) {

            next(from)
            return
        }

        if ( to.meta.requiresPayment && !store.getters.currentUserSubscription.active ) {

            store.state.ui.promptModal = {

                title: secureTranslation('payments.modals.payment-required.title'),
                text: secureTranslation('payments.modals.payment-required.text'),
                bully: false,
                onclose: () => store.state.ui.promptModal = undefined,
                buttons: [
                    { click: () => { router.push({ name: 'Payments' }); store.state.ui.promptModal = undefined }, text: secureTranslation('payments.modals.payment-required.choose-plans'), center: true }
                ]
            }

            next(false)
            return
        }

        next()
    })
}